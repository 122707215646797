import Controller from './application_controller'

/**
 * This controller manages a template that can be attached to a container.
 */
export default class extends Controller {
  static targets = [ "template", "container", "attached" ]
  static classes = [ "insert", "remove" ]

  /**
   * Attaches a single copy of the template to the container.
   * Has no effect if the container already has an element.
   */
  prepend(event) {
    if (this.hasAttachedTarget) {
      event.stopPropagation();
      return;
    }

    window.requestAnimationFrame(t => {
      this.containerTarget.prepend(this.cloneTemplate());
      this.attachedTarget?.querySelector("[autofocus]")?.focus();
    })
  }

  remove(event) {
    if (!this.hasAttachedTarget) {
      return;
    }

    const node = this.attachedTarget;
    if (this.hasRemoveClass) {
      if (this.hasInsertClass) {
        node.classList.remove(this.insertClass);
      }

      node.classList.add(this.removeClass);
      node.addEventListener("animationend", () => node.remove(), { once: true });
    } else {
      node.remove();
    }
  }

  cloneTemplate() {
    const fragment = this.templateTarget.content.cloneNode(true);
    if (this.hasInsertClass) {
      const element = fragment.firstElementChild;
      element.classList.add(this.insertClass);
      element.addEventListener("animationend", () => {
        element.classList.remove(this.insertClass);
      }, { once: true });
    }
    return fragment;
  }
}